import React from 'react';
import Layout from '../components/layout';
import privacyPolicyTemplate from '../../static/privacyAndPolicy';

const privacyPolicyHtml = { __html: privacyPolicyTemplate };

const PrivacyPolicy: React.FC = () => (
  <Layout>
    {/* eslint-disable-next-line react/no-danger */}
    <div dangerouslySetInnerHTML={privacyPolicyHtml} />
  </Layout>
);

export default PrivacyPolicy;
