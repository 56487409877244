module.exports = `
<div class="col-md-12">
  <br/>
  <h1>Privacy Policy</h1>
  <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the norbertbartos.com website.</p>
  <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
  <p>At norbertbartos.com, accessible from https://norbertbartos.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by norbertbartos.com and how we use it.</p>
  <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
  <h2>General Data Protection Regulation (GDPR)</h2>
  <p>We are a Data Controller of your information.</p>
  <p>Norbert Bartos legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
  <ul>
    <li>Norbert Bartos needs to perform a contract with you</li>
    <li>You have given Norbert Bartos permission to do so</li>
    <li>Processing your personal information is in Norbert Bartos legitimate interests</li>
    <li>Norbert Bartos needs to comply with the law</li>
  </ul>
  <p>Norbert Bartos will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>
  <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us. Our Privacy Policy was generated with the help of <a href="https://www.gdprprivacypolicy.net/">GDPR Privacy Policy Generator</a> and the <a href="https://www.app-privacy-policy.com">App Privacy Policy Generator</a>.</p>
  <p>In certain circumstances, you have the following data protection rights:</p>
  <ul>
    <li>The right to access, update or to delete the information we have on you.</li>
    <li>The right of rectification.</li>
    <li>The right to object.</li>
    <li>The right of restriction.</li>
    <li>The right to data portability</li>
    <li>The right to withdraw consent</li>
  </ul>
  <h2>Registration</h2>
  <p>In order to use this website, a user is not required to register. However if you complete our contact form, you will provide specific information, including your name and email address. This information is used to make our communication possible.</p>
  <h2>Log Files</h2>
  <p>norbertbartos.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
  <h2>Google Analytics</h2>
  <p>Our website uses Google Analytics does not identify individual users or associate your IP address with any other data held by Google. We use reports provides by Google Analytics to help us understand website traffic and webpage usage.</p>
  <h2>Cookies</h2>
  <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.</p>
  <p>Our website uses these “cookies” to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>
  <p>Learn more about cookies used on this website for <a href="https://support.google.com/analytics/answer/6004245?hl=en">Google Analytics</a>.</p>
  <h2>Privacy Policies</h2>
  <p>You may consult this list to find the Privacy Policy for each of the advertising partners of norbertbartos.com.</p>
  <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on norbertbartos.com, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
  <p>Note that norbertbartos.com has no access to or control over these cookies that are used by third-party advertisers.</p>
  <h2>Third Party Privacy Policies</h2>
  <p>norbertbartos.com's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.</p>
  <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>
  <h2>Security</h2>
  <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
  <h2>Children's Information</h2>
  <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
  <p>norbertbartos.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
  <h2>Online Privacy Policy Only</h2>
  <p>Our Privacy Policy created at GDPRPrivacyPolicy.net) applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in norbertbartos.com. This policy is not applicable to any information collected offline or via channels other than this website.</p>
  <h2>Changes to This Privacy Policy</h2>
  <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
  <h2>Consent</h2>
  <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
</div>
`;
